import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  userId: undefined,
};

const currentUserSelectedSlice = createSlice({
  name: "currentUserSelectedSlice",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { setUserId } = currentUserSelectedSlice.actions;

export default currentUserSelectedSlice.reducer;
