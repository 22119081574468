import React, { useEffect, useState } from 'react';
import { fetchStreamsApi } from '../../../services/apis/stream';

const Commentators = () => {
  const [streamData, setStreamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getEmbedLink = (url) => {
    try {
      const urlObj = new URL(url);
      const parentDomain = window.location.hostname || "http://localhost:3000/"; 
  
      if (urlObj.hostname.includes("youtube.com") && urlObj.searchParams.get("v")) {
        return `https://www.youtube.com/embed/${urlObj.searchParams.get("v")}`;
      }
      if (urlObj.hostname.includes("youtu.be")) {
        return `https://www.youtube.com/embed/${urlObj.pathname.slice(1)}`;
      }
  
      if (urlObj.hostname.includes("twitch.tv")) {
        const pathSegments = urlObj.pathname.split("/");
        if (pathSegments.length > 1) {
          return `https://player.twitch.tv/?channel=${pathSegments[1]}&parent=${parentDomain}`;
        }
      }
  
      if (urlObj.hostname.includes("vimeo.com")) {
        const vimeoId = urlObj.pathname.split("/")[1];
        return vimeoId ? `https://player.vimeo.com/video/${vimeoId}` : url;
      }
  
      if (urlObj.hostname.includes("dailymotion.com") || urlObj.hostname.includes("dai.ly")) {
        const videoId = urlObj.pathname.split("/").pop();
        return videoId ? `https://www.dailymotion.com/embed/video/${videoId}?parent=${parentDomain}` : url;
      }

      return url;
    } catch (error) {
      console.error("Invalid URL:", url);
      return "";
    }
  };
  

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const response = await fetchStreamsApi({ signal: abortController.signal });
        if (response.status === 200) {
          setStreamData(response.data.data);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error('Error:', error);
          setError('Failed to load streams');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => abortController.abort();
  }, []);

  return (
    <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-2 tw-my-2'>
      {loading ? (
        <div className="tw-text-center tw-col-span-full">Loading streams...</div>
      ) : error ? (
        <div className="tw-text-center tw-text-red-500 tw-col-span-full">{error}</div>
      ) : streamData.length > 0 ? (
        streamData.map((val) => (
          <div key={val.id} className='tw-overflow-hidden tw-rounded-md tw-border tw-relative tw-border-[#9E9E9F]'>
            <div className='tw-px-2 tw-absolute tw-flex tw-items-center tw-justify-between tw-w-full tw-bottom-3'>
              <div className='tw-h-9 tw-w-9 tw-bg-primary-dark tw-opacity-0' />
              <div className="stream-card-shadow tw-bg-[#3E3E3F] tw-py-1 tw-px-4 tw-rounded">
                <h6 className='tw-text-base tw-leading-none'>{val.streamPlatform}</h6>
              </div>
            </div>
            {val.streamLink ? (
              <iframe
                src={getEmbedLink(val.streamLink)}
                height="350px"
                width="100%"
                title={`${val.streamPlatform} stream`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            ) : (
              <div className="tw-text-center tw-text-gray-500 tw-py-4">
                Stream link is unavailable
              </div>
            )}

          </div>
        ))
      ) : (
        <div className="tw-text-center tw-w-full tw-py-4 tw-col-span-full">
          No live streams available at the moment
        </div>
      )}
    </div>
  );
};

export default React.memo(Commentators);